import { Col, Label, Row } from "reactstrap";
import colors from "../config/Color.config";



const IndexPage = () => {
    return (
        <section style={{ height: "100vh", width: "100%", backgroundColor: colors.primary, display: "flex", alignItems: "center", justifyContent: "center", }} >
            <div style={{ width: "70%", height: "50%", borderRadius: 12, backgroundColor: colors.primary, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }} >
                <Row>
                    <Col style={{ textAlign: "center" }} >
                        <Label
                            style={{
                                fontWeight: "bold",
                                fontSize: "8em",
                                color: colors.withe,
                                fontFamily: "'Nunito Sans', 'Oswald', 'Roboto Mono', sans-serif",
                            }}
                        >
                            KHRONOS
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginTop: -40}} >
                    <Col style={{ textAlign: "center" }} >
                        <Label style={{
                            fontSize: "2em", color: colors.withe, fontFamily: "'Nunito Sans', 'Oswald', 'Roboto Mono', sans-serif",
                        }} >
                            ASESORÍA ESPECIALIZADA EN NEGOCIOS
                        </Label>
                    </Col>
                </Row>
            </div>
        </section >
    );
}

export default IndexPage;