
const colors = {
    primary: "#063c6b",
    secondary: "#bcd9e5",
    block01: "#719abd",
    block02: "#7aa5bb",
    block03: "#69859b",
    black: "#000000",
    withe: "#FFFFFF",
    withe01: "#FEFEFE",
    withe02: "#F7F7F7",
    redMoney: "#E40613",
    greenMoney: "#0B983A",
    blueMoney: "#0F70B7",
    grey: "grey"
}


export default colors;