import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index.page";
import React from "react";

const App: React.FC = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexPage />} />
      </Routes>
    </Router>
  );
}

export default App;
